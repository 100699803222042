import { FaFacebookF, FaInstagram, FaLinkedinIn, FaSkype, FaTwitter, FaVimeo, FaWhatsapp } from "react-icons/fa";
import React, {useEffect, useState} from 'react';

const Footer = ({showBelow}) => {
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        }else {
            if (show) setShow(false)
        }
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    const handleClick = () => {
        window[`scrollTo`]({top: 0, behavior: `smooth`})
    }
    return (
        <footer className="rn-footer-area bg-color-black pt--90 pb--60">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.1s">
                            <div className="ft-info">
                                <p>SOLE NAUTICA <br />RENT A BOAT</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.2s">
                            <div className="ft-info">
                                <p>vl. Albert Mužić, Krčina 101,
 <br />51557 Cres</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.4s">
                            <div className="ft-info">
                                <p>krcina28@yahoo.com<br />+385 / 91 580 5907</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--30 mt_sm--30 wow fadeInDown" data-wow-delay="200ms" data-wow-duration="0.6s">
                            <div className="ft-info">
                                <ul className="social-share">
                                   <li><a href="https://m.facebook.com/p/Rent-a-boat-Cres-Sole-nautica-100093973473981/"><FaFacebookF /></a></li>
                                    {/* <li><a href="#link"><FaLinkedinIn /></a></li>
                                    <li><a href="#link"><FaTwitter /></a></li>
                                    <li><a href="#link"><FaInstagram /></a></li>
                                    <li><a href="#link"><FaSkype /></a></li>
                                    <li><a href="#link"><FaVimeo /></a></li> */}
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="float">
                {/* <div className="backtop" onClick={handleClick}>
                    <span className="top"></span>
                </div> */}
                <a href="https://api.whatsapp.com/send?phone=385915805907&text=Rent-A-Boat Cres" class="float" target="_blank">
                <FaWhatsapp style={{color: "white", marginTop: -7, marginLeft: 3}}/>
                </a>
            </div>
        </footer>
    )
}
export default Footer
